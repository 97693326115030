import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import styles from './TeacherReport.module.scss';
import UserInfo from '@/components/UserInfo/UserInfo';
import { authSelector } from '@/store/reducers/auth';
import { useAppSelector } from '@/store';
import SVG from 'react-inlinesvg';
import arrowIcon from './arrow.svg';
import config from '@/config';
import AppLoaderCircle from '@/components/AppLoaderCircle';

const TeacherReport: React.FC<{
    tutorReportData: any
}> = ({ tutorReportData }) => {
    const { user } = useAppSelector(authSelector);
    const [ isSelectorOpen, setIsSelectorOpen ] = useState<boolean>(false);
    const [ reportData, setReportData ] = useState<any>();

    useEffect(() => {
        let isActive = true;

        const loadeData = async() => {
            try {
                const tutor_domain = (window as any).tutor_domain;

                let response = await fetch("https://tutor-conversational-runner.melingo.com/report", {
                //let response = await fetch("http://localhost:5050/report", {
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    "Token": config.apiChatToken,
                    "Secret": config.apiChatSecret,
                    },
                });
                const result = await response.json();
                
                if (isActive) {
                    const reportData: {analysis: string, hasErrors: boolean, errors: any[]} = {
                        analysis: result.analysis || '',
                        hasErrors: false,
                        errors: [],
                    };
    
                    try {
                        const errors = JSON.parse(result.report);
                        const keys = Object.keys(errors);
                        keys.forEach((key) => {
                            const error = errors[key];
                            reportData.errors.push({
                                type: key,
                                description: error.description || '',
                                cefr: error['CEFR level'] || '',
                                count: error.count || 0
                            });
                        })
                    }
                    catch (ex1) {}
    
                    reportData.hasErrors = reportData.errors.length > 0;
                    setReportData(reportData);
                }
            }
            catch (ex) {}
        };

        loadeData()

        return () => {
            isActive = false;
        }
    }, []);
    
    const totalData = useMemo(() => {
        if (!tutorReportData) {
            return null;
        }

        const topicsList = tutorReportData.topicsList || [];
        const topicsListLength = topicsList.length;
        const selectedTopicStr = topicsListLength > 0 ? `${topicsListLength} - ${topicsList[topicsListLength - 1].topic}` : '';

        return {
            totalConversations: tutorReportData.totalConversations,
            totalTimeStr: tutorReportData.totalTimeStr,
            averageTimeStr: tutorReportData.averageTimeStr,
            topicsList: topicsList,
            selectedTopicStr: selectedTopicStr,
        }
    }, [tutorReportData]);

    if (!totalData || !reportData) {
        return <div className={classnames(styles.report, styles.loading)}>
            <AppLoaderCircle />
        </div>;
    }
  
    return <div className={styles.report}>
        <div className={styles.head}>
            {user && (
                <UserInfo
                    fullname={`${user.firstName} ${user.lastName}`}
                    profileImage={user.avatar?.avatarName}
                    backgroundColor={user.avatar?.avatarColor}
                    level={user.level}
                    grade={user.grade}
                />
            )}
            <div className={styles.totalData}>
                <div className={styles.totalDataItem}>
                    <div className={styles.totalDataItemTitle}>Total Conversations</div>
                    <div className={styles.totalDataItemVul}>{totalData.totalConversations}</div>
                </div>
                <div className={styles.totalDataItem}>
                    <div className={styles.totalDataItemTitle}>Total time spent with Tutor</div>
                    <div className={styles.totalDataItemVul}>{totalData.totalTimeStr}</div>
                </div>
                <div className={styles.totalDataItem}>
                    <div className={styles.totalDataItemTitle}>Average Conversation Time</div>
                    <div className={styles.totalDataItemVul}>{totalData.averageTimeStr}</div>
                </div>
            </div>
        </div>
        <div className={styles.body}>
            <div className={classnames(styles.conversation, {[styles.isOpen]: isSelectorOpen})}>
                <div className={styles.conversationTitle}>Conversation</div>
                <div className={styles.conversationSelector}>
                    <div className={styles.conversationSelectorWrapper} onClick={() => {setIsSelectorOpen((prev) => !prev)}}>
                        <div className={styles.conversationSelectorLabel}>{totalData.selectedTopicStr}</div>
                        <SVG src={arrowIcon} />
                    </div>
                    {isSelectorOpen && <div className={styles.conversationSelectorOptions}>
                        {
                            totalData.topicsList.map((topic: any, topicIndex: number) => {
                                return <div key={`conversationSelectorOption--${topicIndex}`} className={styles.conversationSelectorOption} onClick={() => {setIsSelectorOpen(false)}}>
                                    {`${topicIndex + 1} - ${topic.topic}`}
                                </div>   
                            })
                        }
                    </div>}
                </div>
            </div>
            <div className={styles.data}>
                {reportData.hasErrors && <div className={styles.dataItem}>
                    <div className={styles.dataItemTitle} style={{fontWeight: '500'}}>Suggested enhancements activities</div>
                    {reportData.errors.map((error: any, errorIndex: number) => {
                        return <div key={`dataItemError--${errorIndex}`} className={styles.dataItemError}>
                            <div>
                                <div>{error.type}</div>
                                <div>
                                    <div>Errors found:</div>
                                    <div>{error.count}</div>
                                </div>
                            </div>
                            <div>
                                <div>Description:</div>
                                <div>{error.description}</div>
                            </div>
                            <div>
                                <div>CEFR:</div>
                                <div>{error.cefr}</div>
                            </div>
                        </div>;
                    })}
                </div>}
                <div className={styles.dataItem}>
                    <div className={styles.dataItemTitle}>Conversation Summary</div>
                    <div className={styles.dataItemAnalysis}>{reportData.analysis}</div>
                </div>
            </div>
        </div>
    </div>
  };
  
  export default TeacherReport;