import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { ThemeProvider } from './context/ThemeContext';
import './assets/styles/global.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-modern-drawer/dist/index.css';
import 'swiper/css';
import ReactGA from 'react-ga4';

import router from './router';
import store from './store';

import './locales/i18n';
import { RouterProvider } from 'react-router-dom';
import { DrawerProvider } from './context/DrawerContext';
import { LocaleContextProvider } from './context/LocaleContext';

ReactGA.initialize('G-9869RFKRPF');

console.log('app version: avatar demo 2.9');

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ThemeProvider>
    <Provider store={store}>
      <DrawerProvider>
        <LocaleContextProvider>
          <RouterProvider router={router}></RouterProvider>
        </LocaleContextProvider>
      </DrawerProvider>
    </Provider>
  </ThemeProvider>
);
