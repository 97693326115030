import React, { useEffect, useRef, useState, useCallback } from 'react';
import config from '@/config';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import classNames from './TutorChatBody.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import TutorGroupMessagesComp from './TutorGroupMessages/TutorGroupMessages';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import { TutorGroupMessages, TutorSender, TutorMessage, MessageTypes } from '@/types/tutor';
import ChatInput from './ChatInput/ChatInput';
import TeacherReport from './TeacherReport/TeacherReport';


type TopicType = {
  topicId: string;
  topic: string;
  style: string; 
  level: string; 
  l1: string; 
  num_iterations: number; 
  user_role: string; 
  system_role: string;
};

const DEFAULT_TOPIC =  {
  style: 'SMALLTALK', 
  level: 'A1', 
  l1: 'Spanish', 
  num_iterations: 7, 
  user_role: 'Be engaged, answer your questions, ask questions or make comments about the topic', 
  system_role: 'You are a polite tutor that tries to engage the user with smalltalk about the topic'
};

const TOPICS: TopicType[] = [
  {...DEFAULT_TOPIC, topicId: '1', topic: 'Greetings & Leave Takings'},
  {...DEFAULT_TOPIC, topicId: '2', topic: 'Classroom Routines'},
  {...DEFAULT_TOPIC, topicId: 'ShoppingForBakingIngredients', topic: 'Shopping In Store', style: 'ROLEPLAY', user_role: 'A buyer that wants to buy ingredients for something you would like to bake.', system_role: 'A seller that assists the user in buying ingredients for their baking plan'},
  {...DEFAULT_TOPIC, topicId: '6', level: 'A2', topic: 'Around The School'},
  {...DEFAULT_TOPIC, topicId: '7', level: 'A2', topic: 'Classroom Rules'},
  {...DEFAULT_TOPIC, topicId: '8', level: 'A2', topic: 'Following Instructions'},
];

const TOPICS_GROUPS = TOPICS.reduce((groupsData, topic) => {
  const groups = groupsData.groups as any[];

  if (!groupsData.groupsMapper.has(topic.level)) {
    const newGroup: {level: string, topics: TopicType[]} = {level: topic.level, topics: []};
    groups.push(newGroup);
    groupsData.groupsMapper.set(topic.level, newGroup);
  }

  const group = groupsData.groupsMapper.get(topic.level);
  group.topics.push(topic);

  return groupsData;
}, {
  groups: [],
  groupsMapper: new Map<string, any>()
}).groups.sort((g1: any, g2: any) => {
  return g1.level > g2.level ? 1 : -1;
});

type TutorChatBody = {
  pauseRecording?: boolean;
  speak?: (text: string) => void;
  className?: string;
  onTopicSelect: (topicId: string) => void;
  onReport: (showReport: boolean) => void;
};

const SCROLL_TIME_OUT = 100;

const getTimeString = (date: Date): string => {
  let h: any = date.getHours();
  let m: any = date.getMinutes();

  if (h < 10) {
    h = '0' + h;
  }

  if (m < 10) {
    m = '0' + m;
  }

  return `${h}:${m}`;
};

if (!(window as any).tutorReportData) {
  (window as any).tutorReportData = {
    totalConversations: 1,
    totalTime: 240000,
    averageTime: 240000 / 1,
    totalTimeStr: '4m',
    averageTimeStr: '4m',
    topicsList: [TOPICS[4]],
    getTimeString: (time: number) => {
      const minutes = Math.floor((time  / (1000 * 60)) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const parts: string[] = hours > 0 ? [`${hours}h`, `${minutes}m`] : [`${minutes}m`];
      return parts.join(' ');
    },
    addConversations: (time: number, topic: TopicType) => {
      const _tutorReportData = (window as any).tutorReportData;
      _tutorReportData.totalConversations++;
      _tutorReportData.totalTime += time;
      _tutorReportData.averageTime = _tutorReportData.totalTime / _tutorReportData.totalConversations;
      _tutorReportData.totalTimeStr = _tutorReportData.getTimeString(_tutorReportData.totalTime);
      _tutorReportData.averageTimeStr = _tutorReportData.getTimeString(_tutorReportData.averageTime);
      _tutorReportData.topicsList.push(topic);
    },
  };
}
const tutorReportData = (window as any).tutorReportData;

const TutorChatBody: React.FC<TutorChatBody> = ({ pauseRecording = false, speak, className, onTopicSelect, onReport }) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const isActive = useRef<boolean>(false);
  const startTime = useRef<Date>(new Date());
  const startTimeTikets = useRef<number>(Date.now());
  const chatBodyRef = useRef<HTMLDivElement | null>(null);
  const [ isStart, setIsStart ] = useState(true);
  const [ isQuit, setIsQuit ] = useState(false);
  const [ userQuery, setUserQuery ] = useState('');
  const messagesRef = useRef<TutorGroupMessages[]>([]);
  const firstMessageTimerRef = useRef<any>();
  const [ messages, _setMessages ] = useState<TutorGroupMessages[]>([]);
  const [ ShowTeacherReport, setShowTeacherReport ] = useState<boolean>(false);
  const selectedTopicRef = useRef<TopicType>();

  const startChat = () => {
    if (firstMessageTimerRef.current) {
      clearTimeout(firstMessageTimerRef.current);
      firstMessageTimerRef.current = null;
    }

    if (!isActive.current) {
      return;
    }

    startTime.current = new Date();
    startTimeTikets.current = Date.now();
    setIsQuit(false);
    setIsStart(true);

    const startMessage: TutorGroupMessages = {
      sender: TutorSender.tutor,
      message: [{
        title: null,
        text: `Hi ${user?.firstName}. I'd be happy to talk to you. What would you like to talk about? Choose one of the following topics or suggest your own topic.`,
        hasSeen: false,
        loading: true,
        exercise: null,
        type: MessageTypes.Response,
        crossedOut: false,
        explanation: undefined,
        responseOptions: TOPICS_GROUPS,
      }],
      timeStamp: getTimeString(new Date()),
      includeThumbs: false,
    };
    messagesRef.current = [startMessage];
    _setMessages([startMessage]);

    firstMessageTimerRef.current = setTimeout(() => {
      const _startMessage: TutorGroupMessages = {
        ...startMessage,
        message: [{
          ...startMessage.message[0],
          loading: false
        }]
      };
      messagesRef.current = [startMessage];
      _setMessages([_startMessage]);
    }, 2000);
  };

  useEffect(() => {
    isActive.current = true;

    startChat();

    return () => {
      isActive.current = false;

      if (firstMessageTimerRef.current) {
        clearTimeout(firstMessageTimerRef.current);
        firstMessageTimerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (chatBodyRef.current) {
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
      }
    }, SCROLL_TIME_OUT);

    return () => clearTimeout(timer);
  }, [messages]); 

  const setMessages = useCallback((messages: TutorGroupMessages[]) => {
    messagesRef.current = messages;
    _setMessages(messages);
  }, []);

  const sendUserMessage = useCallback(async (userMessage: TutorGroupMessages, userMessageIndex: number) => {
    setIsStart(false);
    const tutorMessage: TutorGroupMessages = {
      sender: TutorSender.tutor,
      message: [{
        text: '',
        hasSeen: false,
        loading: true,
        type: MessageTypes.Response,
      }],
      timeStamp: getTimeString(new Date()),
      includeThumbs: false,
    };
    const tutorMessageIndex = messagesRef.current.length;
    let _messages: TutorGroupMessages[] = [...messagesRef.current, tutorMessage];
    setMessages(_messages);

    let isEnd = false;
    if (userMessage.message[0].text === 'quit') {
      setIsQuit(true);
      tutorReportData.addConversations(Date.now() - startTimeTikets.current, selectedTopicRef.current);
      isEnd = true;
    }

    let result: any;
    try {
      let body_data: any = {};
      if (isStart) {
        body_data = {
          ...DEFAULT_TOPIC,
          topic: userMessage.message[0].text
        };
        const user_text =  (userMessage.message[0].text || '').toString().toLowerCase();
        const topic = TOPICS.filter(t => t.topic.toLowerCase() === user_text);
        if (topic && topic.length > 0) {
          body_data = {
            ...body_data,
            ...topic[0]
          };
          selectedTopicRef.current = topic[0];
        }
      }

      if (body_data.topicId) {
        onTopicSelect(body_data.topicId);
        delete body_data.topicId;
      }

      const body = isStart ? {
        mode: "init",
        topic: userMessage.message[0].text,
        ...body_data,
      } : {
        mode: "ongoing",
        user_text: userMessage.message[0].text,
        ...body_data,
      };

      const tutor_domain = (window as any).tutor_domain;

      let response = await fetch("https://tutor-conversational-runner.melingo.com/chat", {
      //let response = await fetch("http://localhost:5050/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Token": config.apiChatToken,
          "Secret": config.apiChatSecret,
        },
        body: JSON.stringify(body),
      });
      result = await response.json();
    }
    catch (error) {
      result = {
        "conversation_continuation": "",
        "conversation_continuation_with_correction": "",
        "corrected_version": "",
        "diffs": [],
        "enhancement_area": "",
        "explanation": "",
        "explanation_output": "",
        "explanation_spanish": "",
        "mode": "Conversation",
        "response": "I didn't quite understand you. Please try again. Make sure your microphone is working and that there is no background noise."
      };
    }
    
    /*
    const p = new Promise((reverse, rejects) => {
      setTimeout(() => {
        reverse({
          "conversation_continuation": "What is your cat's name?",
          "conversation_continuation_with_correction": "You could say: I have a cat. What is your cat's name?",
          "corrected_version": "I have a cat.",
          "diffs": [
              [
                  "a cat.",
                  "cat"
              ]
          ],
          "enhancement_area": "grammar: articles",
          "explanation": "Added 'a' before 'cat' to make the sentence grammatically correct.",
          "explanation_output": "Añadí 'a' antes de 'cat' para hacer la oración gramaticalmente correcta.",
          "explanation_spanish": "Añadí 'a' antes de 'cat' para hacer la oración gramaticalmente correcta.",
          "mode": "Conversation",
          "response": "What is your cat's name?"
        });
      }, 10);
    });
    const result: any = await p;
    */

    if (isActive.current) {
      _messages = [...messagesRef.current];
      _messages[tutorMessageIndex].message[0].loading = false;
      _messages[tutorMessageIndex].message[0].text = result.response || '';
      _messages[tutorMessageIndex].message[0].speakText = result.conversation_continuation_with_correction || result.response || '';

      if (userMessage.message[0].text === 'show' || userMessage.message[0].text === 'report') {
        _messages[tutorMessageIndex].message[0].text = JSON.stringify(result, undefined, 4);
        _messages[tutorMessageIndex].message[0].speakText = '!!!';
      }

      if (result.error) {
        _messages[tutorMessageIndex].message[0].text = 'Please suggest another topic.';
        _messages[tutorMessageIndex].message[0].speakText = 'Please suggest another topic.';
        _messages[tutorMessageIndex].message[0].responseOptions = TOPICS_GROUPS;
        setIsStart(true);
      }

      if (result.corrected_version) {
        _messages[userMessageIndex].message = [..._messages[userMessageIndex].message, {
          text: result.corrected_version,
          hasSeen: true,
          loading: false,
          type: MessageTypes.Response,
          explanation: result.explanation_spanish || result.explanation_output || result.explanation || undefined,
        }];

        if (result.diffs) {
          let userText: string = _messages[userMessageIndex].message[0].text as string;
          result.diffs.forEach((deff: string[]) => {
            if (deff.length > 1) {
              userText = userText.replace(deff[1], `<s>${deff[1]}</s>`);
            }
          });
          _messages[userMessageIndex].message[0].text = userText;
        }

        _messages[userMessageIndex].message[0].hasCorrectedVersion = true;
      }

      if (result.status && result.status === "end") {
        setIsQuit(true);
        if (!isEnd) {
          tutorReportData.addConversations(Date.now() - startTimeTikets.current, selectedTopicRef.current);
        }
        isEnd = true;
      }

      setMessages(_messages);
    }
    
  }, [isStart, setMessages]);

  const createUserMessage = useCallback((text: string) => {
    setUserQuery(''); 

    let _messages: TutorGroupMessages[] = [...messages];
    if (_messages.length > 0 && _messages[_messages.length - 1].message.length > 0) {
      delete _messages[_messages.length - 1].message[_messages[_messages.length - 1].message.length - 1].responseOptions;
    }

    const userMessage: TutorGroupMessages = {
      sender: TutorSender.user,
      message: [{
        text: text,
        hasSeen: true,
        type: MessageTypes.Response,
      }],
      timeStamp: getTimeString(new Date()),
    };
    const userMessageIndex = _messages.length;

    _messages = [..._messages, userMessage];
    setMessages(_messages);

    sendUserMessage(userMessage, userMessageIndex);
  }, [messages, sendUserMessage, setMessages]);

  const handleSubmit = useCallback(async (event?: React.FormEvent<HTMLFormElement>): Promise<void> => {
    if (event) {
      event.preventDefault();
    }

    if (userQuery) {
      createUserMessage(userQuery); 
    }
  }, [userQuery, createUserMessage]);

  const onResponseOptionsSelectHandle = useCallback((option: string) => {
    createUserMessage(option);
  }, [createUserMessage]);

  const onMessageSeenHandle = useCallback((groupIndex: number, messageIndex: number) => {
    const _messages: TutorGroupMessages[] = [...messages];
    if (_messages.length > groupIndex && _messages[groupIndex].message.length > messageIndex) {
      _messages[groupIndex].message[messageIndex].hasSeen = true;
    }
    setMessages(_messages);
  }, [messages, setMessages]);

  const onExplanationOpenHandle = useCallback((groupIndex: number, messageIndex: number) => {
    const _messages: TutorGroupMessages[] = [...messages];
    if (_messages.length > groupIndex && _messages[groupIndex].message.length > messageIndex) {
      const explanation = _messages[groupIndex].message[messageIndex].explanation;
      _messages[groupIndex].message[messageIndex].explanation = undefined;
      _messages[groupIndex].message = [..._messages[groupIndex].message, {
        text: explanation,
        hasSeen: true,
        loading: false,
        type: MessageTypes.Explanation,
      }];

      if (speak && explanation) {
        speak(explanation);
      }
    }
    setMessages(_messages);
  }, [messages, setMessages]);

  const onShowTeacherReportHandle = useCallback(() => {
    setShowTeacherReport(true);
    onReport(true);
  }, []);

  const onHideTeacherReportHandle = useCallback(() => {
    setShowTeacherReport(false);
    onReport(false);
    startChat();
  }, []);

  return (
    <div className={classes(classNames.chatBody, className, classNames.isDashboard)} ref={chatBodyRef} >
      <div>
        <div className={classNames.info}>
          <p>{t('tutorStartedAt', { timeStart: getTimeString(startTime.current) })}</p>
        </div>
        {messages.map((message, index) => <TutorGroupMessagesComp 
          key={index} 
          index={index} 
          payload={message} 
          speak={speak} 
          onResponseOptionsSelect={onResponseOptionsSelectHandle}
          onMessageSeen={onMessageSeenHandle}
          onExplanationOpen={onExplanationOpenHandle}
        />)}
      </div>
      {!isQuit && <ChatInput userQuery={userQuery} setUserQuery={setUserQuery} handleSubmit={handleSubmit} pauseRecording={pauseRecording} />}
      {isQuit && !ShowTeacherReport && <div>
        <button className={classNames.showTeacherReportBtn} onClick={onShowTeacherReportHandle}>show teacher report</button>
      </div>}
      {isQuit && ShowTeacherReport && <TeacherReport tutorReportData={tutorReportData} />}
    </div>
  );
};

export default React.memo(TutorChatBody);
